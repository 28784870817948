// .form-item-selector .btn
$form-item-selector-button-background-color: #fff;

// .form-item-selector .btn (xs)
$form-item-selector-btn__xs-font-size: 0.8rem;
$form-item-selector-btn__xs-padding-y: $spacer * 0.25;
$form-item-selector-btn__xs-padding-x: $spacer * 0.25;

// .form-item-selector .btn:active, .form-item-selector .btn:active
$form-item-selector-btn-active-color: #fff;
$form-item-selector-btn-active-background-color: $app-color-primary;
