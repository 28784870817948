// .panel
$panel-background-color: $app-color-white;
$panel-box-shadow: 0 4px 1rem rgba($black, .25);
$panel-border-radius: 5px;
$panel-paddings: (
        "top": $spacer,
        "right": $spacer,
        "bottom": $spacer,
        "left": $spacer
);
$panel-borders: (
        "top": none,
        "right": none,
        "bottom": none,
        "left": none
);

// PanelSecondary
$panel-secondary-background-color: $app-color-primary-light-3;

// .panel .panel
$panel-child-box-shadow: 0 4px 1rem rgba($black, .5);